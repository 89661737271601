/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import dynamic from 'next/dynamic';

import { IPrismicImage, IPrismicText, IPrismicUrl } from '@lib/prismic/types';
import { asText } from '@lib/prismic/util/document';
import { userInExperimentVariation } from '@lib/experiment';
import env from '@lib/envs/env';
import useAssignToBranchJourneyBannerExperiment from '@hooks/Experiment/useAssignToBranchJourneyBannerExperiment';
import { ExperimentVariationTitles } from '@models/Experiment';
import UserContext from '@context/User/user-context';
import { IReview } from '@components/shared/Reviews/Reviews';
import MobileAppConversionBanner from
  '@components/shared/MobileAppConversionBanner/MobileAppConversionBanner';
// import defaultLinkTracking from '@components/pages/Home/lib/defaultLinkTracking';
import IPrismicReviews from '@components/shared/Reviews/i-prismic-reviews';
// import FullBleedSliderHero from '@components/shared/Hero/FullBleedSliderHero/FullBleedSliderHero';
import HowHavenlyWorks from '@components/pages/Home/components/HowHavenlyWorks/HowHavenlyWorks';
import BeforeAndAfter from '@components/pages/Home/components/BeforeAndAfter/BeforeAndAfter';
import DesignHelp from '@components/pages/Home/components/DesignHelp/DesignHelp';
import InstagramFeed from '@components/pages/Home/components/InstagramFeed/InstagramFeed';
import HappinessGuarantee from '@components/pages/Home/components/HappinessGuarantee/HappinessGuarantee';
import { IFeed } from '@lib/instagram/client';
import { scroller } from 'react-scroll';
import { useRouter } from 'next/router';
import SaleHero from './components/SaleHero/SaleHero';
import styles from './Home.module.scss';

const { IOS_APP_URL_SCHEME_BASE } = env;

const Press = dynamic(() => import('./components/Press/Press'));
const Packages = dynamic(() => import('@components/shared/Packages/Packages'));
const OurPartners = dynamic(() => import('./components/OurPartners/OurPartners'));
const Reviews = dynamic(() => import('@components/shared/Reviews/Reviews'));

interface IHomeProps {
  prismicData: IPrismicData;
  featuredBoards: any;
  instagramFeeds: IFeed[];
}

interface IPrismicData extends IPrismicReviews {
  hero_subtitle?: IPrismicText[];
  hero_cta_text: IPrismicText[];
  hero_cta_link: IPrismicUrl;
  hero_cta_logged_in_link: IPrismicUrl;
  our_partners_eyebrow: IPrismicText[];
  our_partner_image: IPrismicImage;
  our_partners_text: IPrismicText[];
}

const Home = ({
  prismicData, instagramFeeds
}: IHomeProps) => {
  const {
    //hero_subtitle,
    //hero_cta_text,
    //hero_cta_link,
    //hero_cta_logged_in_link,
    our_partners_eyebrow,
    our_partner_image,
    our_partners_text,
    reviews_subtitle,
    reviews_cta,
    reviews_list,
  } = prismicData;
  const userContext = useContext(UserContext);
  const router = useRouter();

  useAssignToBranchJourneyBannerExperiment();

  const isInBranchJourneyBannerExperiment = userInExperimentVariation(
    ExperimentVariationTitles.BRANCH_JOURNEY_BANNER_TEST_A,
    userContext.experimentVariations || []
  );

  return (
    <>
      {/* Put this hero back after sale
      <FullBleedSliderHero
        hero_subtitle={hero_subtitle}
        hero_cta_text={hero_cta_text}
        hero_cta_link={hero_cta_link}
        hero_cta_logged_in_link={hero_cta_logged_in_link}
        ctaOnClick={(e) => defaultLinkTracking('get_started', e?.target)}
      />
      */}

      {/* Used during President's sale */}
      <SaleHero
        saleName="PRESIDENTS DAY SALE"
        catchPhrase={<>Once-a-Year Deals <em className={styles.Emphase}>You Can’t Miss</em></>}
        deals={[
          {
            percent: 50,
            category: 'Interior Design Services',
            ctaText: 'Buy Now',
            onClick: () => {
              scroller.scrollTo('packages-section', {
                duration: 500,
                delay: 100,
                smooth: true,
              });
            }
          },
          {
            upTo: true,
            percent: 70,
            category: 'Furniture and Decor',
            ctaText: 'Shop Now',
            onClick: () => {
              router.push('shop/collection/sale?page=1');
            }
          },
        ]}
      />

      <HowHavenlyWorks />

      <Packages />

      <Press />

      <BeforeAndAfter />

      <Reviews
        title="Reviews"
        newHomepage
        subtitle={asText(reviews_subtitle)}
        cta={asText(reviews_cta)}
        reviews={reviews_list.map((review: any): IReview => ({
          author: asText(review.author),
          title: asText(review.title),
          text: asText(review.text),
          stars: review.stars,
        }))}
      />

      <DesignHelp />

      {!!instagramFeeds.length && (
        <InstagramFeed feeds={instagramFeeds} />
      )}

      <OurPartners
        eyebrow={asText(our_partners_eyebrow)}
        image={our_partner_image}
        title={asText(our_partners_text)}
        newHomepage
      />

      { isInBranchJourneyBannerExperiment && (
        <MobileAppConversionBanner
          iosDeeplinkPath={`${IOS_APP_URL_SCHEME_BASE}://havenly.com/`}
        />
      )}

      <HappinessGuarantee />
    </>
  );
};

export default Home;
