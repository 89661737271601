/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import { asImageSrc } from '@prismicio/client';

import { IPrismicImage } from '@lib/prismic/types';
import AspectRatioBox from '@components/shared/AspectRatioBox/AspectRatioBox';

import { useMinBreakpoint } from '@hooks/useMediaQuery/useMediaQuery';
import styles from './PrismicImage.module.scss';

interface IPrismicImageProps {
  className?: string;
  image: IPrismicImage;
  lazy?: boolean;
  breakpoints: {
    mobile?: number;
    tablet?: number;
    desktop?: number;
    largeDesktop?: number;
  };
  noAspectRatioBox?: boolean; // quick solution for unexpected issue, might remove later.
}
/**
 * A component to render a responsive PrismicImage.
 * Results in minimal CLS by setting intrinsic image height based on display.
 * Image is 100% width and lazy loaded by default, override by passing className prop / lazy=false.
 */
export default function PrismicImage({
  className = '',
  image,
  lazy = true,
  breakpoints,
  noAspectRatioBox,
}: IPrismicImageProps) {
  if (!image) return null;

  const {
    alt = '',
    mobile_view,
    tablet_view,
    desktop_view,
    large_desktop_view
  } = image;

  const {
    mobile, tablet, desktop, largeDesktop
  } = breakpoints || {};

  const { width, height } = useIntrinsicDimensions(image, {
    mobile,
    tablet,
    desktop,
    largeDesktop
  });

  const calcAspectRatio = () => height / width;
  const [aspectRatio, setAspectRatio] = useState(calcAspectRatio);
  useEffect(() => {
    setAspectRatio(calcAspectRatio());
  });

  const Image = (
    { withWrapper, wrapper, children }: any
  ) => (withWrapper ? wrapper(children) : children);

  return (
    <div className={className}>
      <Image
        withWrapper={!noAspectRatioBox}
        wrapper={(children: any) => (
          <AspectRatioBox aspectRatio={aspectRatio}>{children}</AspectRatioBox>
        )}
      >
        <picture>
          {mobile && mobile_view && (
            <source media={`(max-width: ${mobile - 1}px)`} srcSet={getSrcSet(mobile_view)} />
          )}
          {tablet && tablet_view && (
            <source media={`(max-width: ${tablet - 1}px)`} srcSet={getSrcSet(tablet_view)} />
          )}
          {desktop && desktop_view && (
            <source
              media={`(max-width: ${desktop - 1}px)`}
              srcSet={getSrcSet(desktop_view)}
            />
          )}
          {largeDesktop && large_desktop_view && (
            <source
              media={`(max-width: ${largeDesktop - 1}px)`}
              srcSet={getSrcSet(large_desktop_view)}
            />
          )}
          <source srcSet={getSrcSet(image)} />
          <img
            alt={alt || ''}
            srcSet={getSrcSet(image)}
            className={styles.PrismicImage}
            width={width}
            height={height}
            loading={lazy ? 'lazy' : 'eager'}
          />
        </picture>
      </Image>
    </div>
  );
}

function useIntrinsicDimensions(
  image: IPrismicImage,
  breakpoints: {
    mobile?: number;
    tablet?: number;
    desktop?: number;
    largeDesktop?: number;
  }
) {
  const {
    mobile_view,
    tablet_view,
    desktop_view,
    large_desktop_view,
    url: defaultSrc
  } = image;
  const {
    mobile: mobileBreakpoint,
    tablet: tabletBreakpoint,
    desktop: desktopBreakpoint,
    largeDesktop: largeDesktopBreakpoint
  } = breakpoints;
  const { width: defaultWidth, height: defaultHeight } =
    image?.dimensions || {};

  const isLargerThanMobile = useMinBreakpoint(mobileBreakpoint);
  const isLargerThanTablet = useMinBreakpoint(tabletBreakpoint);
  const isLargerThanDesktop = useMinBreakpoint(desktopBreakpoint);
  const isLargerThanLargeDesktop = useMinBreakpoint(largeDesktopBreakpoint);

  const calculateDimensions = () => {
    if (!isLargerThanMobile && mobile_view) {
      const { dimensions: mobileDimensions, url: mobileSrc } = mobile_view;
      const { width: mobileWidth, height: mobileHeight } = mobileDimensions;
      return {
        src: mobileSrc,
        width: mobileWidth,
        height: mobileHeight,
      };
    }

    if (!isLargerThanTablet && tablet_view) {
      const { dimensions: tabletDimensions, url: tabletSrc } = tablet_view;
      const { width: tabletWidth, height: tabletHeight } = tabletDimensions;
      return {
        src: tabletSrc,
        width: tabletWidth,
        height: tabletHeight,
      };
    }

    if (!isLargerThanDesktop && desktop_view) {
      const { dimensions: desktopDimensions, url: desktopUrl } = desktop_view;
      const { width: desktopWidth, height: desktopHeight } = desktopDimensions;
      return {
        src: desktopUrl,
        width: desktopWidth,
        height: desktopHeight,
      };
    }

    if (!isLargerThanLargeDesktop && large_desktop_view) {
      const { dimensions: largeDesktopDimensions, url: largeDesktopUrl } = large_desktop_view;
      const { width: largeDesktopWidth, height: largeDesktopHeight } = largeDesktopDimensions;
      return {
        src: largeDesktopUrl,
        width: largeDesktopWidth,
        height: largeDesktopHeight,
      };
    }

    return {
      src: defaultSrc,
      width: defaultWidth,
      height: defaultHeight,
    };
  };

  const [dimensions, setDimensions] = useState({
    src: defaultSrc,
    width: defaultWidth,
    height: defaultHeight,
  });

  useEffect(() => {
    setDimensions(calculateDimensions());
  }, [
    isLargerThanDesktop,
    isLargerThanTablet,
    isLargerThanMobile,
    isLargerThanLargeDesktop,
    image
  ]);

  return dimensions;
}

function getSrcSet(image: IPrismicImage): string {
  const onex = asImageSrc(image as any, {
    dpr: 1,
  });

  const twox = asImageSrc(image as any, {
    dpr: 2,
  });

  const threex = asImageSrc(image as any, {
    dpr: 2,
  });

  return `${threex} 3x, ${twox} 2x, ${onex}`;
}
