import React, { Fragment } from 'react';
import { getResizedImageUrl } from '@lib/image';
import styles from './SaleHero.module.scss';

interface IDeal {
  upTo?: boolean;
  percent: number;
  category: string;
  ctaText: string;
  onClick: () => void;
}

export default function SaleHero({
  className = '',
  saleName,
  catchPhrase,
  deals
}: {
  className?: string;
  saleName: string;
  catchPhrase: string | JSX.Element;
  deals: IDeal[];
}) {
  return (
    <div
      className={`${styles.SaleHero} ${className}`}
    >
      <picture>
        <source
          media="(min-width: 1620px)"
          srcSet={getResizedImageUrl({
            quality: 90,
            url: 'https://static.havenly.com/home/sales/dps_merch_desktop_2_1620.jpg',
            width: 1620
          })
          }
        />
        <source
          media="(min-width: 1440px)"
          srcSet={getResizedImageUrl({
            quality: 90,
            url: 'https://static.havenly.com/home/sales/dps_merch_desktop_2_1440.jpg',
            width: 1440
          })
          }
        />
        <source
          media="(min-width: 1024px)"
          srcSet={getResizedImageUrl({
            quality: 90,
            url: 'https://static.havenly.com/home/sales/dps_merch_desktop_2_1024.jpg',
            width: 1024
          })
          }
        />
        <source
          media="(min-width: 768px)"
          srcSet={getResizedImageUrl({
            quality: 90,
            url: 'https://static.havenly.com/home/sales/merchonly_tablet_2_768.jpg',
            width: 768
          })
          }
        />
        <source
          media="(max-width: 767px)"
          srcSet={getResizedImageUrl({
            quality: 90,
            url: 'https://static.havenly.com/home/sales/merchonly_phone_2_375.jpg',
            width: 375
          })
          }
        />
        <img
          src="https://static.havenly.com/home/sales/dps_merch_desktop_2_1620.jpg"
          alt="Hero"
          className={styles.HeroImage}
        />
      </picture>
      <div className={styles.HeroContentContainer}>
        <div className={styles.SaleName}>{saleName}</div>
        <div className={styles.CatchPhrase}>
          <strong>{catchPhrase}</strong>
        </div>
        <div className={styles.DealSection}>
          <div className={styles.DealBlocks}>
            {deals.map((deal, idx) => (
              <Fragment key={deal.category}>
                <div className={styles.Deal}>
                  <div className={styles.Small}>{deal.upTo ? 'Up to' : ''}</div>
                  <div style={{ display: 'flex' }}>
                    <div className={styles.BigNumber}>{deal.percent}</div>
                    <div>
                      <div className={styles.SmallOperator}>%</div>
                      <div className={styles.SmallOperator}>off</div>
                    </div>
                  </div>
                  <div className={styles.Small}>
                    <strong>{deal.category}</strong>
                  </div>
                  <div>
                    <button
                      className={styles.Button}
                      onClick={deal.onClick}
                      type="button"
                    >
                      {deal.ctaText}
                    </button>
                  </div>
                </div>
                {idx !== deals.length - 1 && <div className={styles.Divider} />}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
