import gql from 'graphql-tag';

export default gql`
  query GetProductModalVendorVariantById($id: ID!) {
    vendorVariantById(id: $id) {
      id
      title
      retailPrice {
        id
        price
      }
      availability
      color
      dimensions
      material
      productUrl
      productGroupUrl
      groupCount
      vendorPromotion {
        id
        promoText
        shipping
        terms
      }
      vendorTieredDiscountCode {
        code
        discountType
        maxSavingsPercentage
        minSavingsValue
        maxSavingsValue
        lowestDiscountPrice
        highestDiscountPrice
      }
      images {
        id
        uri
      }
      reviewStats {
        average
        count
      },
      price: currentPrice
      saleOnSalePrice {
        price
        percentage
      }
      totalDiscountPercent
      isOnSale
      vendor {
        id
        name
      }
      groupCount
      designAward
    }
  }
`;
